import { AnimatePresence, m, useIsomorphicLayoutEffect } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { useState, FormEvent, useRef } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useIsFiltersPopupOpenedValue } from '@/atoms/opened-popups';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useAllDialColorsShowState } from '@/atoms/catalog/all-dial-colors';
import { useSetAllDialColorsWidthState } from '@/atoms/catalog/all-dial-colors-width';

interface Props {
    dialColors: string[];
}

const AllDialColors = ({ dialColors }: Props) => {
    const [showAllDialColors, setShowAllDialColors] = useAllDialColorsShowState();
    const [searchedDialColors, setSearchedDialColors] = useState(dialColors);
    const [filtersState, setFiltersState] = useFiltersState();
    const allDialColors = useRef<HTMLDivElement>(null);
    const setWidth = useSetAllDialColorsWidthState();
    const [device] = useMediaQueryDeviceState();
    const isFiltersOpened = useIsFiltersPopupOpenedValue();
    const [DialColorsInputValue, setDialColorsInputValue] = useState('');

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (!isFiltersOpened) {
                setShowAllDialColors(true);
                setTimeout(() => {
                    if (allDialColors.current) {
                        setWidth(allDialColors.current.offsetWidth);
                        setTimeout(() => {
                            setShowAllDialColors(false);
                        }, 1);
                    }
                }, 1);
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFiltersOpened]);

    return (
        <AnimatePresence>
            {showAllDialColors && (
                <m.div
                    className="all-brands js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allDialColors}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllDialColors(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllDialColors(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Цвет циферблата</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label={'поиск цвета циферблата'}
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent) => {
                                const eventTarget = event.target as HTMLInputElement;
                                setSearchedDialColors(
                                    dialColors.filter((item) => {
                                        return item.toLowerCase().includes(eventTarget.value.toLowerCase());
                                    }),
                                );
                                setDialColorsInputValue(eventTarget.value);
                            }}
                            value={DialColorsInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list all-DialColors-list">
                        {searchedDialColors.map((dialColor, i) => {
                            return (
                                dialColor && (
                                    <FilterCheckbox
                                        key={i}
                                        name={dialColor}
                                        value={dialColor}
                                        checked={
                                            !!filtersState['dial_color[]'] &&
                                            filtersState['dial_color[]'].length > 0 &&
                                            !!filtersState['dial_color[]'].find((item) => item === dialColor)
                                        }
                                        onChange={(arg) => {
                                            setFiltersState(
                                                produce((draft) => {
                                                    if (arg) {
                                                        draft['dial_color[]']?.push(dialColor);
                                                    } else {
                                                        draft['dial_color[]'] = draft['dial_color[]']?.filter(
                                                            (item) => item !== dialColor,
                                                        );
                                                    }
                                                }),
                                            );
                                        }}
                                    >
                                        {dialColor}
                                    </FilterCheckbox>
                                )
                            );
                        })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllDialColors;
