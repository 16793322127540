import { AnimatePresence, m, useIsomorphicLayoutEffect } from 'framer-motion';
import { useAllBrandsShowState } from '@/atoms/catalog/all-brands';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { Brand } from '@/api/brands';
import { useState, FormEvent, useRef } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useIsFiltersPopupOpenedValue } from '@/atoms/opened-popups';
import { useSetAllBrandsWidthState } from '@/atoms/catalog/all-brands-width';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';

interface Props {
    brands: Brand[];
}

const AllBrands = ({ brands }: Props) => {
    const [showAllBrands, setShowAllBrands] = useAllBrandsShowState();
    const [searchedBrands, setSearchedBrands] = useState(brands);
    const [filtersState, setFiltersState] = useFiltersState();
    const allBrands = useRef<HTMLDivElement>(null);
    const setWidth = useSetAllBrandsWidthState();
    const [device] = useMediaQueryDeviceState();
    const isFiltersOpened = useIsFiltersPopupOpenedValue();
    const [brandsInputValue, setBrandsInputValue] = useState('');

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (!isFiltersOpened) {
                setShowAllBrands(true);
                setTimeout(() => {
                    if (allBrands.current) {
                        setWidth(allBrands.current.offsetWidth);
                        setTimeout(() => {
                            setShowAllBrands(false);
                        }, 1);
                    }
                }, 1);
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFiltersOpened]);

    return (
        <AnimatePresence>
            {showAllBrands && (
                <m.div
                    className="all-brands js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allBrands}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllBrands(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllBrands(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Бренд</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label={'поиск бренда'}
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent) => {
                                const eventTarget = event.target as HTMLInputElement;
                                setSearchedBrands(
                                    brands.filter((item) => {
                                        return item.name.toLowerCase().includes(eventTarget.value.toLowerCase());
                                    }),
                                );
                                setBrandsInputValue(eventTarget.value);
                            }}
                            value={brandsInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list">
                        {searchedBrands.map((brand, i) => {
                            return (
                                brand.name && (
                                    <FilterCheckbox
                                        key={i}
                                        name={brand.name}
                                        value={brand.id.toString()}
                                        checked={
                                            !!filtersState['brands[]'] &&
                                            filtersState['brands[]'].length > 0 &&
                                            !!filtersState['brands[]'].find((item: number) => item === brand.id)
                                        }
                                        onChange={(arg) => {
                                            setFiltersState(
                                                produce((draft) => {
                                                    if (arg) {
                                                        draft['brands[]']?.push(brand.id);
                                                    } else {
                                                        draft['brands[]'] = draft['brands[]']?.filter(
                                                            (item: number) => item !== brand.id,
                                                        );
                                                    }
                                                }),
                                            );
                                        }}
                                    >
                                        {brand.name}
                                    </FilterCheckbox>
                                )
                            );
                        })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllBrands;
