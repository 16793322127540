import { Brand } from '@/api/brands';
import { Material } from '@/api/materials';
import Button from '@/components/shared/Button';
import Popup from '@/components/shared/Popup';
import { useIsomorphicLayoutEffect, m } from 'framer-motion';
import { CSSProperties, useState } from 'react';
import AllBrands from './AllBrands';
import { useAllBrandsShowState } from '@/atoms/catalog/all-brands';
import { useIsFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import RangeSlider from '@/components/shared/RangeSlider/RangeSlider';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { Filters, useFiltersState } from '@/atoms/catalog/filters-state';
import useDebouncedFunction from '@/hooks/use-debounced-function';
import { produce } from 'immer';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects/index';
import { useAllBrandsWidthValue } from '@/atoms/catalog/all-brands-width';
import { useAllMaterialsShowState } from '@/atoms/catalog/all-materials';
import AllMaterials from './AllMaterials';
import { useDefaultPricesValue } from '@/atoms/catalog/filter-default-prices-state';
import { useProductTypeValue } from '@/atoms/catalog/filter-product-type-state';
import { useProductInsertValue } from '@/atoms/catalog/filter-product-insert-state';
import { useProductColorValue } from '@/atoms/catalog/filter-product-color-state';
import { useDefaultDiametersValue } from '@/atoms/catalog/filter-default-diameter-state';
import { useAllMaterialsWidthValue } from '@/atoms/catalog/all-materials-width';
import { useDefaultPowersValue } from '@/atoms/catalog/filter-default-powers-state';
import { PresetFilterItem } from '@/types';
import { useProductGenderValue } from '@/atoms/catalog/filter-product-gender-state';
import { useProductCollectionValue } from '@/atoms/catalog/filter-product-collection-state';
import { useProductDialColorsValue } from '@/atoms/catalog/filter-product-dial-colors-state';
import { useAllDialColorsShowState } from '@/atoms/catalog/all-dial-colors';
import { useAllDialColorsWidthValue } from '@/atoms/catalog/all-dial-colors-width';
import AllDialColors from './AllDialColors';
import { FilterFields } from '@/api/productFilters';

interface Props {
    brands: Brand[];
    materials: Material[];
    initialQueryState: Record<string, any>;
    presetFilters: PresetFilterItem[];
    filter: Partial<FilterFields>;
}

const gendersDict = {
    0: 'мужские',
    1: 'женские',
    2: 'унисекс',
};

export const FILTERS_POPUP_NAME = 'catalog-filters';
const filtersToShowLength = 13;

const clearFilters = (obj: Filters) => {
    const newObj = { ...obj };

    for (const key in newObj) {
        const _key = key as keyof Filters;

        if (Array.isArray(newObj[_key])) {
            newObj[
                (_key as 'brands[]' | 'materials[]' | 'type[]' | 'color[]' | 'insert[]' | 'collection[]',
                'dial_color[]')
            ] = [];
        } else {
            newObj[_key] = undefined;
        }
        if (_key === 'category') {
            newObj['category'] = obj['category'];
        }

        if (_key === 'gender[]') {
            newObj['gender[]'] = [];
        }
    }

    document.dispatchEvent(new Event('clear-filters'));

    return newObj;
};

const FiltersPopup = ({ brands, materials, filter, initialQueryState, presetFilters }: Props) => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFiltersPopupOpenedValue();
    const [namesWidth, setNamesWidth] = useState(0);
    const [showAllBrands, setShowAllBrands] = useAllBrandsShowState();
    const [showAllMaterials, setShowAllMaterials] = useAllMaterialsShowState();
    const [showAllDialColors, setShowAllDialColors] = useAllDialColorsShowState();
    const [filtersState, setFiltersState] = useFiltersState();
    const router = useRouter();
    const [popupAnimating, setPopupAnimating] = useState(true);
    const materialsWidth = useAllMaterialsWidthValue();
    const brandsWidth = useAllBrandsWidthValue();
    const dialColorsWidth = useAllDialColorsWidthValue();

    const defaultPrice = useDefaultPricesValue();
    const defaultPower = useDefaultPowersValue();
    const defaultDiameters = useDefaultDiametersValue();
    const productTypes = useProductTypeValue();
    const productInserts = useProductInsertValue();
    const productColors = useProductColorValue();
    const productGenders = useProductGenderValue();
    const productCollection = useProductCollectionValue();
    const productDialColors = useProductDialColorsValue();

    const dialColors = filter.dial_color ?? [];

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            const names = Array.from(document.querySelectorAll<HTMLElement>('.js-filter-row-name'));
            setNamesWidth(Math.max(...names.map((item) => item.offsetWidth)));
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const updatePrice = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.priceMin = min;
                draft.priceMax = max;
            }),
        );
    }, 100);

    const updateDiameter = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.diameterMin = min;
                draft.diameterMax = max;
            }),
        );
    }, 100);

    const updatePower = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.powerMin = min;
                draft.powerMax = max;
            }),
        );
    }, 100);

    return (
        <Popup
            name={FILTERS_POPUP_NAME}
            className={classNames('filters-popup', {
                'is-animating': popupAnimating,
                'all-brands--opened': showAllBrands,
                'all-materials--opened': showAllMaterials,
                'all-dial-colors--opened': showAllDialColors,
            })}
            overlay
        >
            <m.div
                className="filters-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
                onAnimationStart={() => {
                    setPopupAnimating(true);
                }}
                onAnimationComplete={() => {
                    setPopupAnimating(false);
                }}
                style={
                    {
                        '--all-brands-width': `${brandsWidth}px`,
                        '--all-materials-width': `${materialsWidth}px`,
                        '--all-dial-colors-width': `${dialColorsWidth}px`,
                    } as CSSProperties
                }
            >
                <div className="filters-popup__main">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">Фильтры</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(FILTERS_POPUP_NAME);
                            }}
                            aria-label="Закрыть фильтры"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>
                    <div
                        className="filters-body"
                        style={namesWidth !== 0 ? ({ '--names-width': `${namesWidth}px` } as CSSProperties) : undefined}
                    >
                        {productTypes && productTypes.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Тип</div>
                                <div className="filter-row__list">
                                    {productTypes.map((productType, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={productType}
                                                value={productType}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['type[]']?.push(productType);
                                                            } else {
                                                                draft['type[]'] = draft['type[]']?.filter(
                                                                    (item: string) => item !== productType,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['type[]'] &&
                                                    filtersState['type[]'].length > 0 &&
                                                    !!filtersState['type[]'].find(
                                                        (item: string) => item === productType,
                                                    )
                                                }
                                            >
                                                {productType}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {brands.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Бренд</div>
                                <div className="filter-row__list">
                                    {brands.slice(0, filtersToShowLength).map((brand, i) => {
                                        return (
                                            brand.name && (
                                                <FilterCheckbox
                                                    key={i}
                                                    name={brand.name}
                                                    value={brand.id.toString()}
                                                    checked={
                                                        !!filtersState['brands[]'] &&
                                                        filtersState['brands[]'].length > 0 &&
                                                        !!filtersState['brands[]'].find(
                                                            (item: number) => item === brand.id,
                                                        )
                                                    }
                                                    onChange={(arg) => {
                                                        setFiltersState(
                                                            produce((draft) => {
                                                                if (arg) {
                                                                    draft['brands[]']?.push(brand.id);
                                                                } else {
                                                                    draft['brands[]'] = draft['brands[]']?.filter(
                                                                        (item: number) => item !== brand.id,
                                                                    );
                                                                }
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    {brand.name}
                                                </FilterCheckbox>
                                            )
                                        );
                                    })}
                                    {brands.length > filtersToShowLength && (
                                        <Button
                                            className={classNames('all-brands-button m-text-xs', {
                                                'is-open': showAllBrands,
                                            })}
                                            onClick={() => {
                                                setShowAllBrands((prev) => !prev);
                                                setShowAllMaterials(false);
                                                setShowAllDialColors(false);
                                            }}
                                            icon={<ArrowMoreSVG />}
                                        >
                                            Ещё
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {defaultPrice &&
                            defaultPrice.min !== null &&
                            defaultPrice.max !== null &&
                            defaultPrice.min !== defaultPrice.max && (
                                <>
                                    <div className="filter-row">
                                        <div className="filter-row__name js-filter-row-name m-text-xs">Цена</div>
                                        <div className="filter-row__list">
                                            <RangeSlider
                                                min={defaultPrice.min}
                                                max={defaultPrice.max}
                                                currentMin={filtersState.priceMin}
                                                currentMax={filtersState.priceMax}
                                                step={10}
                                                prefix="$ "
                                                onChange={updatePrice}
                                            />
                                        </div>
                                    </div>
                                    {presetFilters.length > 0 && (
                                        <div className="filter-row">
                                            <div className="filter-row__name js-filter-row-name m-text-xs">
                                                Популярные цены
                                            </div>
                                            <div className="filter-row__list">
                                                {presetFilters.slice(0, filtersToShowLength).map((priceFilter, i) => {
                                                    if (priceFilter.field === 'price') {
                                                        const priceMin = priceFilter.min ?? defaultPrice.min;
                                                        const priceMax = priceFilter.max ?? defaultPrice.max;

                                                        const isChecked =
                                                            filtersState.priceMin === priceMin &&
                                                            filtersState.priceMax === priceMax;

                                                        return (
                                                            priceFilter.name && (
                                                                <FilterCheckbox
                                                                    key={i}
                                                                    name={priceFilter.name}
                                                                    value={i.toString()}
                                                                    checked={isChecked}
                                                                    onChange={(arg) => {
                                                                        setFiltersState(
                                                                            produce((draft) => {
                                                                                if (arg) {
                                                                                    draft.priceMin =
                                                                                        priceMin ?? undefined;
                                                                                    draft.priceMax =
                                                                                        priceMax ?? undefined;
                                                                                } else {
                                                                                    draft.priceMin = undefined;
                                                                                    draft.priceMax = undefined;
                                                                                }
                                                                            }),
                                                                        );
                                                                    }}
                                                                >
                                                                    {priceFilter.name}
                                                                </FilterCheckbox>
                                                            )
                                                        );
                                                    }
                                                })}
                                                {/* Убрал временно: тут неправильная логика */}
                                                {/* {presetFilters.length > filtersToShowLength && (
                                                    <Button
                                                        className={classNames('all-brands-button m-text-xs', {
                                                            'is-open': showAllPopularPrices,
                                                        })}
                                                        onClick={() => {
                                                            setShowAllPopularPrices((prev) => !prev);
                                                            setShowAllBrands(false);
                                                            setShowAllMaterials(false);
                                                            setShowAllDialColors(false);
                                                        }}
                                                        icon={<ArrowMoreSVG />}
                                                    >
                                                        Ещё
                                                    </Button>
                                                )} */}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                        {defaultPower &&
                            defaultPower.min !== null &&
                            defaultPower.max !== null &&
                            defaultPower.min !== defaultPower.max && (
                                <div className="filter-row">
                                    <div className="filter-row__name js-filter-row-name m-text-xs">Запас хода</div>
                                    <div className="filter-row__list">
                                        <RangeSlider
                                            min={defaultPower.min}
                                            max={defaultPower.max}
                                            currentMin={filtersState.priceMin}
                                            currentMax={filtersState.priceMax}
                                            step={10}
                                            suffix=" Ч"
                                            onChange={updatePower}
                                        />
                                    </div>
                                </div>
                            )}

                        <div className="filter-row">
                            <div className="filter-row__name js-filter-row-name m-text-xs">Наличие</div>
                            <div className="filter-row__list">
                                <FilterCheckbox
                                    name="available"
                                    value="1"
                                    type="radio"
                                    checked={
                                        typeof filtersState.available !== 'undefined' && filtersState.available === 1
                                    }
                                    onChange={(arg) => {
                                        setFiltersState(
                                            produce((draft) => {
                                                if (arg) {
                                                    draft.available = 1;
                                                } else {
                                                    draft.available = undefined;
                                                }
                                            }),
                                        );
                                    }}
                                >
                                    в наличии
                                </FilterCheckbox>
                                <FilterCheckbox
                                    name="available"
                                    value="0"
                                    type="radio"
                                    checked={
                                        typeof filtersState.available !== 'undefined' && filtersState.available === 0
                                    }
                                    onChange={(arg) => {
                                        setFiltersState(
                                            produce((draft) => {
                                                if (arg) {
                                                    draft.available = 0;
                                                } else {
                                                    draft.available = undefined;
                                                }
                                            }),
                                        );
                                    }}
                                >
                                    под заказ
                                </FilterCheckbox>
                            </div>
                        </div>

                        <div className="filter-row">
                            <div className="filter-row__name js-filter-row-name m-text-xs">состояние</div>
                            <div className="filter-row__list">
                                <FilterCheckbox
                                    name="state"
                                    value="1"
                                    type="radio"
                                    checked={filtersState.state === 1}
                                    onChange={(arg) => {
                                        setFiltersState(
                                            produce((draft) => {
                                                if (arg) {
                                                    draft.state = 1;
                                                } else {
                                                    draft.state = undefined;
                                                }
                                            }),
                                        );
                                    }}
                                >
                                    new
                                </FilterCheckbox>
                                <FilterCheckbox
                                    name="state"
                                    value="0"
                                    type="radio"
                                    checked={typeof filtersState.state !== 'undefined' && filtersState.state === 0}
                                    onChange={(arg) => {
                                        setFiltersState(
                                            produce((draft) => {
                                                if (arg) {
                                                    draft.state = 0;
                                                } else {
                                                    draft.state = undefined;
                                                }
                                            }),
                                        );
                                    }}
                                >
                                    not new
                                </FilterCheckbox>
                            </div>
                        </div>

                        {materials.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Материал</div>
                                <div className="filter-row__list">
                                    {materials.slice(0, filtersToShowLength).map((material, i) => {
                                        return (
                                            material.name && (
                                                <FilterCheckbox
                                                    key={i}
                                                    name={material.name}
                                                    value={material.id.toString()}
                                                    checked={
                                                        !!filtersState['materials[]'] &&
                                                        filtersState['materials[]'].length > 0 &&
                                                        !!filtersState['materials[]'].find(
                                                            (item: number) => item === material.id,
                                                        )
                                                    }
                                                    onChange={(arg) => {
                                                        setFiltersState(
                                                            produce((draft) => {
                                                                if (arg) {
                                                                    draft['materials[]']?.push(material.id);
                                                                } else {
                                                                    draft['materials[]'] = draft['materials[]']?.filter(
                                                                        (item: number) => item !== material.id,
                                                                    );
                                                                }
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    {material.name}
                                                </FilterCheckbox>
                                            )
                                        );
                                    })}
                                    {materials.length > filtersToShowLength && (
                                        <Button
                                            className={classNames('all-brands-button m-text-xs', {
                                                'is-open': showAllMaterials,
                                            })}
                                            onClick={() => {
                                                setShowAllMaterials((prev) => !prev);
                                                setShowAllBrands(false);
                                                setShowAllDialColors(false);
                                            }}
                                            icon={<ArrowMoreSVG />}
                                        >
                                            Ещё
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {defaultDiameters &&
                            defaultDiameters.min !== null &&
                            defaultDiameters.max !== null &&
                            defaultDiameters.min !== defaultDiameters.max && (
                                <>
                                    <div className="filter-row">
                                        <div className="filter-row__name js-filter-row-name m-text-xs">диаметр</div>
                                        <div className="filter-row__list">
                                            <RangeSlider
                                                min={defaultDiameters.min}
                                                max={defaultDiameters.max}
                                                onChange={updateDiameter}
                                                step={1}
                                                suffix=" мм"
                                            />
                                        </div>
                                    </div>
                                    {presetFilters.length > 0 && (
                                        <div className="filter-row">
                                            <div className="filter-row__name js-filter-row-name m-text-xs">
                                                Популярные размеры
                                            </div>
                                            <div className="filter-row__list">
                                                {presetFilters.slice(0, filtersToShowLength).map((sizeFilter, i) => {
                                                    if (sizeFilter.field === 'diameter') {
                                                        const minDiameter = sizeFilter.min ?? defaultDiameters.min;
                                                        const maxDiameter = sizeFilter.max ?? defaultDiameters.max;

                                                        const isChecked =
                                                            filtersState.diameterMin === minDiameter &&
                                                            filtersState.diameterMax === maxDiameter;

                                                        return (
                                                            sizeFilter.name && (
                                                                <FilterCheckbox
                                                                    key={i}
                                                                    name={sizeFilter.name}
                                                                    value={i.toString()}
                                                                    checked={isChecked}
                                                                    onChange={(arg) => {
                                                                        setFiltersState(
                                                                            produce((draft) => {
                                                                                if (arg) {
                                                                                    draft.diameterMin =
                                                                                        minDiameter ?? undefined;
                                                                                    draft.diameterMax =
                                                                                        maxDiameter ?? undefined;
                                                                                } else {
                                                                                    draft.diameterMin = undefined;
                                                                                    draft.diameterMax = undefined;
                                                                                }
                                                                            }),
                                                                        );
                                                                    }}
                                                                >
                                                                    {sizeFilter.name}
                                                                </FilterCheckbox>
                                                            )
                                                        );
                                                    }
                                                })}
                                                {/* Убрал временно: тут неправильная логика */}
                                                {/* {presetFilters.length > filtersToShowLength && (
                                                    <Button
                                                        className={classNames('all-brands-button m-text-xs', {
                                                            'is-open': showAllPopularDiameters,
                                                        })}
                                                        onClick={() => {
                                                            setShowAllPopularDiameters((prev) => !prev);
                                                            setShowAllBrands(false);
                                                            setShowAllMaterials(false);
                                                            setShowAllDialColors(false);
                                                        }}
                                                        icon={<ArrowMoreSVG />}
                                                    >
                                                        Ещё
                                                    </Button>
                                                )} */}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                        {productCollection && productCollection.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Коллекция</div>
                                <div className="filter-row__list">
                                    {productCollection.map((collection, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={collection}
                                                value={collection}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['collection[]']?.push(collection);
                                                            } else {
                                                                draft['collection[]'] = draft['collection[]']?.filter(
                                                                    (item: string) => item !== collection,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['collection[]'] &&
                                                    filtersState['collection[]'].length > 0 &&
                                                    !!filtersState['collection[]'].find(
                                                        (item: string) => item === collection,
                                                    )
                                                }
                                            >
                                                {collection}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {productGenders && productGenders.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Пол</div>
                                <div className="filter-row__list">
                                    {productGenders.map((productGender, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={productGender.toString()}
                                                value={productGender.toString()}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['gender[]']?.push(productGender);
                                                            } else {
                                                                draft['gender[]'] = draft['gender[]']?.filter(
                                                                    (item: number) => item !== productGender,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['gender[]'] &&
                                                    filtersState['gender[]'].length > 0 &&
                                                    (!!filtersState['gender[]'].find(
                                                        (item: number) => item === productGender,
                                                    ) ||
                                                        filtersState['gender[]'].find(
                                                            (item: number) => item === productGender,
                                                        ) === 0)
                                                }
                                            >
                                                {gendersDict[productGender as 0 | 1 | 2]}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {productDialColors && productDialColors.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Цвет циферблата</div>
                                <div className="filter-row__list">
                                    {productDialColors.slice(0, filtersToShowLength).map((dialColor, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={dialColor}
                                                value={dialColor}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['dial_color[]']?.push(dialColor);
                                                            } else {
                                                                draft['dial_color[]'] = draft['dial_color[]']?.filter(
                                                                    (item: string) => item !== dialColor,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['dial_color[]'] &&
                                                    filtersState['dial_color[]'].length > 0 &&
                                                    !!filtersState['dial_color[]'].find(
                                                        (item: string) => item === dialColor,
                                                    )
                                                }
                                            >
                                                {dialColor}
                                            </FilterCheckbox>
                                        );
                                    })}
                                    {productDialColors.length > filtersToShowLength && (
                                        <Button
                                            className={classNames('all-brands-button m-text-xs', {
                                                'is-open': showAllMaterials,
                                            })}
                                            onClick={() => {
                                                setShowAllDialColors((prev) => !prev);
                                                setShowAllBrands(false);
                                                setShowAllMaterials(false);
                                            }}
                                            icon={<ArrowMoreSVG />}
                                        >
                                            Ещё
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}

                        {productInserts && productInserts.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Вставка</div>
                                <div className="filter-row__list">
                                    {productInserts.map((productInsert, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={productInsert}
                                                value={productInsert}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['insert[]']?.push(productInsert);
                                                            } else {
                                                                draft['insert[]'] = draft['insert[]']?.filter(
                                                                    (item: string) => item !== productInsert,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['insert[]'] &&
                                                    filtersState['insert[]'].length > 0 &&
                                                    !!filtersState['insert[]'].find(
                                                        (item: string) => item === productInsert,
                                                    )
                                                }
                                            >
                                                {productInsert}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}

                        {productColors && productColors.length > 0 && (
                            <div className="filter-row">
                                <div className="filter-row__name js-filter-row-name m-text-xs">Цвет</div>
                                <div className="filter-row__list">
                                    {productColors.map((productColor, i) => {
                                        return (
                                            <FilterCheckbox
                                                key={i}
                                                name={productColor}
                                                value={productColor}
                                                onChange={(arg) => {
                                                    setFiltersState(
                                                        produce((draft) => {
                                                            if (arg) {
                                                                draft['color[]']?.push(productColor);
                                                            } else {
                                                                draft['color[]'] = draft['color[]']?.filter(
                                                                    (item: string) => item !== productColor,
                                                                );
                                                            }
                                                        }),
                                                    );
                                                }}
                                                checked={
                                                    !!filtersState['color[]'] &&
                                                    filtersState['color[]'].length > 0 &&
                                                    !!filtersState['color[]'].find(
                                                        (item: string) => item === productColor,
                                                    )
                                                }
                                            >
                                                {productColor}
                                            </FilterCheckbox>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="filters-footer">
                        <Button
                            geometryVariant="mustache"
                            onClick={() => {
                                router.push(
                                    {
                                        pathname: router.pathname,
                                        query: {
                                            ...removeNullishAndUndefinedFromObject(clearFilters(router.query)),
                                            page: 1,
                                        },
                                    },
                                    undefined,
                                    { shallow: true },
                                );
                                setFiltersState(clearFilters(filtersState));
                                closePopup(FILTERS_POPUP_NAME);
                            }}
                        >
                            Сбросить
                        </Button>
                        <Button
                            geometryVariant="mustache"
                            onClick={() => {
                                router.push(
                                    {
                                        pathname: router.pathname,
                                        query: removeNullishAndUndefinedFromObject({
                                            ...router.query,
                                            ...filtersState,
                                            sort: router.query.sort,
                                            order: router.query.order,
                                            page: 1,
                                        }),
                                    },
                                    undefined,
                                    { shallow: true },
                                );
                                closePopup(FILTERS_POPUP_NAME);
                            }}
                        >
                            Принять
                        </Button>
                    </div>
                </div>

                <AllBrands brands={brands} />
                <AllMaterials materials={materials} />
                <AllDialColors dialColors={dialColors} />
            </m.div>
        </Popup>
    );
};

export default FiltersPopup;
