import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allDialColorsWidthState = atom<number>({
    key: 'allDialColorsWidthState',
    default: 0,
});

export const useAllDialColorsWidthValue = () => useRecoilValue(allDialColorsWidthState);

export const useSetAllDialColorsWidthState = () => useSetRecoilState(allDialColorsWidthState);
